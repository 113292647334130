import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Ralfs from "../img/Ralfs.jpg";
import RalfsHover from "../img/pixel_ralfs.jpg";
import Miks from "../img/miks.jpg";
import MiksHover from "../img/pixel_miks.jpg";

const CompanyMembers = () => {
    const members = [
        {
            name: 'Ralfs Dambītis',
            role: 'Līdzdibinātājs, Izstrādātājs',
            description: 'Vizionārs vadītājs un uzņēmējs, kura galvenais mērķis ir izaugsme',
            src: Ralfs,
            hoverSrc: RalfsHover,
        },
        {
            name: 'Miks Miķelsons',
            role: 'Līdzdibinātājs, Izstrādātājs',
            description: 'Atvērts un māksliniecisks izstrādātājs, kuru virza inovācijas',
            src: Miks,
            hoverSrc: MiksHover,
        },
    ];

    return (
        <section className="text-white py-12">
            <div className="container mx-auto">
                <h2 className="text-2xl md:text-5xl text-white font-bold mb-10 md:mb-20 text-center">Iepazīsties ar Mums</h2>
                <Swiper
                    modules={[Navigation, Pagination]}
                    spaceBetween={30}
                    speed={1000}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    loop={true}
                    className="swiper-full"
                >
                    {members.map((member, index) => (
                        <SwiperSlide key={index}>
                            <div className="text-center h-[70vh] flex flex-col lg:flex-row items-center justify-center gap-20">
                                <div className="relative group">
                                    <img
                                        src={member.src}
                                        alt={member.name}
                                        className="w-48 h-48 md:w-64 md:h-64 lg:w-96 lg:h-96 object-cover object-top rounded-full shadow-2xl mb-4 transform transition duration-1000 ease-in-out group-hover:scale-110 group-hover:opacity-0"
                                    />
                                    <img
                                        src={member.hoverSrc}
                                        alt={`${member.name} Hover`}
                                        className="absolute inset-0 w-48 h-48 md:w-64 md:h-64 lg:w-96 lg:h-96 object-cover object-top rounded-full shadow-2xl mb-4 transform transition duration-1000 ease-in-out opacity-0 group-hover:opacity-100 group-hover:scale-110"
                                    />
                                </div>
                                <div>
                                    <p className="text-lg md:text-2xl font-bold text-white">{member.name}</p>
                                    <p className="text-base md:text-lg text-white">{member.role}</p>
                                    <p className="text-base md:text-lg text-white px-4 md:px-0">{member.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default CompanyMembers;