import React from 'react';
import {LuBrain} from "react-icons/lu";
import { TbBrain } from "react-icons/tb";
import { GiBrain } from "react-icons/gi";


const Prices = () => {

    return (
        <div id="Prices" className="min-h-screen flex flex-col items-center justify-center p-2 md:p-8 bg-gray-900 pt-20">
            {/* Image Container */}
            <div className="text-4xl text-white font-bold mb-5 text-center">
                <h1>
                    <span className="text-blueCustom">Cenas</span>
                </h1>
            </div>
            <div className="w-40 h-1 bg-white"></div>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-20 gap-10">
                {/* Easy Plan */}
                <div
                    className="flex flex-col items-center text-center bg-gray-800 p-8 rounded-lg shadow-lg border border-blueCustom">
                        <h2 className="text-2xl font-bold text-blueCustom mb-4 flex flex-row gap-2 text-center">Vienkāršs <LuBrain/></h2>
                    <p className="text-base md:text-lg font-bold text-white">Vienkāršajā līmenī tiek iekļauts:</p>
                    <ul className="text-sm md:text-base text-white text-left mt-4 list-disc">
                        <li className="py-2">Vienkāršs dizains</li>
                        <li className="py-2">Viena vietne, ar tādām sadaļām kā, "Par", "Pakalpojumi", "Kontakti" utt.
                        </li>
                        <li className="py-2">Responsīvs dizains, kas ir pielāgots visām ierīcēm (Mobīlajam telefonam,
                            planšetei, portatīvajam, utt)
                        </li>
                        <li className="py-2">Hipersaite uz Jūsu sociālajiem tīkliem</li>
                    </ul>
                    <div className="text-3xl md:text-4xl text-blueCustom font-bold mt-auto">
                        <h3>200-500 Euro</h3>
                    </div>
                    <div className="text-xl md:text-2xl">
                        <h4>Uzturēšanas maksa - 10-15 Euro</h4>
                    </div>
                </div>

                {/* Average Plan */}
                <div
                    className="flex flex-col items-center text-center bg-gray-800 p-8 rounded-lg shadow-lg border border-blueCustom">
                    <h2 className="text-2xl font-bold text-blueCustom mb-4 flex flex-row gap-2 text-center">Viduvējs <TbBrain /></h2>
                    <p className="text-base md:text-lg font-bold text-white">Vidējā līmenī tiek iekļauts:</p>
                    <ul className="text-sm md:text-base text-white text-left mt-4 list-disc">
                        <li className="py-2">Radošāks mājaslapas izkārtojums, kas saskan ar Jūsu logo</li>
                        <li className="py-2">Mājaslapa ar vairākām sadaļām, (Home, About, Services, Blog, Contact,
                            utt.)
                        </li>
                        <li className="py-2">Kustīgi elementi, piemēram, bildes, kas pašas mainās, slaideri utml.</li>
                        <li className="py-2">Kontaktu ievadformas priekš e-pasta</li>
                        <li className="py-2">Responsīvs dizains, kas ir pielāgots visām ierīcēm (Mobīlajam telefonam,
                            planšetei, portatīvajam, utt)
                        </li>
                        <li className="py-2">Hipersaite uz Jūsu sociālajiem tīkliem</li>
                    </ul>
                    <div className="text-3xl md:text-4xl text-blueCustom font-bold mt-auto">
                        <h3>500-900 Euro</h3>
                    </div>
                    <div className="text-xl md:text-2xl">
                        <h4>Uzturēšanas maksa - 15-30 Euro</h4>
                    </div>
                </div>

                {/* Complex Plan */}
                <div
                    className="flex flex-col items-center text-center bg-gray-800 p-8 rounded-lg shadow-lg border border-blueCustom">
                    <h2 className="text-2xl font-bold text-blueCustom mb-4 flex flex-row gap-2 text-center">Inovatīvāk <GiBrain /></h2>
                    <p className="text-base md:text-lg font-bold text-white">Inovatīvajā līmenī tiek iekļauts:</p>
                    <ul className="text-sm md:text-base text-white text-left mt-4 list-disc">
                        <li className="py-2">Radošāks mājaslapas izkārtojums, kas saskan ar jūsu logo, krāsas un
                            elementu izvietojums pēc Jūsu pieprasījuma
                        </li>
                        <li className="py-2">Mājaslapa ar vairākām sadaļām, (Home, About, Services, Blog, Contact,
                            utt.)
                        </li>
                        <li className="py-2">Kustīgi elementi, bildes, kas mainās, teksts, kas kustās, lai piesaistītu
                            cilvēku uzmanību, slaideri, utml.
                        </li>
                        <li className="py-2">Pievienot iespēju priekš valodu maiņas</li>
                        <li className="py-2">Kontaktu ievadformas priekš e-pasta</li>
                        <li className="py-2">Responsīvs dizains, kas ir pielāgots visām ierīcēm (Mobīlajam telefonam,
                            planšetei, portatīvajam, utt)
                        </li>
                        <li className="py-2">Hipersaite uz Jūsu sociālajiem tīkliem</li>
                    </ul>
                    <div className="text-3xl md:text-4xl text-blueCustom font-bold mt-auto">
                        <h3>900-1500 Euro</h3>
                    </div>
                    <div className="text-xl md:text-2xl">
                        <h4>Uzturēšanas maksa - 30-50 Euro</h4>
                    </div>
                </div>
                <a href="/" className="text-blue-500 text-2xl underline underline-offset-8 z-50">Back to Home</a>
            </div>
        </div>
    );
}

export default Prices;